.btn-primary {
    /* color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac; */
}

/* CSS */
.button-30 {
    align-items: center;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow:
        rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395a;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 48px;
    /* width: 10em; */
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition:
        box-shadow 0.15s,
        transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
}

.button-30:focus {
    box-shadow:
        #d6d6e7 0 0 0 1.5px inset,
        rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
    box-shadow:
        rgba(45, 35, 66, 0.4) 0 4px 8px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px,
        #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
}

.button-30:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
}

.btn-30-del-filter{
    color: #ff4d4f;
    height: auto;
    width: 2em;
    margin-left: 0.1em;
}

.btn-30-accept-filter{
    color: #52c41a;
    height: auto;
    width: 2em;
}

.button-icon {
    margin-right: 1em;
}

/* Scroll to top button */
.top-to-btm {
    position: relative;
}
.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}
