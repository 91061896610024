.herohead {
    height: 70%;
    position: absolute;
    z-index: 0;
    top: -3em;
    left: 0;
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
    box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
}

@media screen and (max-width: 575.98px) {
    .herohead-mobile {
        height: 90% !important;
    }
}

.herohead-35 {
    height: 35em;
    position: absolute;
    z-index: 0;
    top: -3em;
    left: 0;
    border-bottom-right-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
    box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
}

.hero-subtitle {
    font-size: 2.5em;
    font-weight: 700;
    color: white;
    text-shadow:
        0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
}

.hero-content{
    position: relative;
    z-index: 50 !important;
    margin-bottom: 2rem;
}

.hero-title {
    margin-bottom: 1rem;
    color: white;
    text-shadow:
        0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 575.96px) {
    .after-hero-mobile {
        margin-top: 13rem !important;
    }
}

.after-hero {
    margin-top: 18rem !important;
}

.after-hero-home {
    margin-top: 13rem;
}

@media only screen and (min-width: 1921px) {
    .after-hero-home-xl {
        margin-top: 20rem;
    }
}

.hero-home-bg {
    background-image: url(../img/hero-home-bg.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.hero-reviews-bg {
    background-image: url(../img/hero-reviews-bg.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    filter: brightness(60%);
}

.hero-overons-bg {
    background-image: url(../img/hero-overons-bg.jpg);
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
    filter: brightness(90%);
}

.hero-contact-bg {
    background-image: url(../img/hero-contact-bg.jpg);
    background-size: cover;
    background-position: top;
    background-attachment: fixed;
}
