:root {
    --primaryColor: #1b6ec2;
    --secondaryColor: #727272;
}

.info-box-icon {
    font-size: 6rem;
    color: white;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.info-box-icon:hover {
    color: white;
}

.info-box-icon-pink {
    color: pink;
}

.info-box-icon-blue {
    color: lightblue;
}

.info-box-icon-yellow {
    color: orange;
}

.info-box-icon-box {
    background-image: url(../img/green-background-pattern.jpg);
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.contact-form-icon {
    font-size: 6rem;
    color: green;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.card-custom {
    border-radius: 1rem !important;

    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    &:hover {
        transition: all 0.2s ease-out;
        border: 1px solid #cccccc;
        background-color: white;
        top: -2px;
        box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 12px 12px -7px rgba(0, 0, 0, 0.75);
    }

    &:hover:before {
        transform: scale(2.15);
    }
}

.card-custom-review {
    margin-right: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.card-custom-review-ratingtotal {
    margin-left: 1rem;
    margin-right: 15vh;
}

.hidden {
    display: none;
}

.ratings {
    font-size: 2rem;
}

/* Star rating */
.div-stars {
    /* width: 270px; */
    display: inline-block;
    width: fit-content;
}

div.stars_infocard {
    font-size: 3em;
}

div.stars_reviewscard {
    font-size: 1.5em;
}

@media only screen and (max-width: 575.96px) {
    .div-reviews-mobile {
        height: auto !important;
    }
}

.div-reviews {
    height: 69.4em;

    overflow-y: hidden;
    overflow-x: hidden;
}

.div-companyList {
    margin-top: 7.4em;
}

.bottom-left-alert {
    position: fixed;
    bottom: 1em;
    left: 1em;
}

input.star {
    display: none;
}

label.star {
    float: right;
    padding: 10px;
    font-size: 2em;
    color: #707070;
    transition: all 0.2s;
}

input.star:checked ~ label.star:before {
    content: "\f005";
    color: #fd4;
    transition: all 0.25s;
}

input.star-5:checked ~ label.star:before {
    color: #fe7;
    text-shadow: 0 0 20px rgb(231, 178, 32);
}

input.star-3:checked ~ label.star:before {
    color: rgb(67, 194, 105);
}

input.star-2:checked ~ label.star:before {
    color: rgb(48, 214, 186);
}

input.star-1:checked ~ label.star:before {
    color: rgb(172, 23, 85);
}

label.star:hover {
    transform: rotate(20deg) scale(1.2);
}

@media screen and (max-width: 400px) {
    .card-custom-review-stars-div-mobile {
        float: center !important;
    }
}

@media screen and (min-width: 401px) {
    .card-custom-review-stars-div {
        float: right;
    }
}

.icon-link {
    width: 1.5em;
    height: 1.5em;
}

.custom-row {
    padding-right: 0 !important;
}

/* -----------------------------------------------------------------------------

# Base - Genral & Typography

----------------------------------------------------------------------------- */
html {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    height: 100%;
}

body {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.1px;
    line-height: 1.8;
    color: #051922;
    overflow-x: hidden;

    background-color: #eee;
}

body img {
    max-width: 100%;
}

a {
    color: #1ae0b5;
    text-decoration: none;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

a:hover {
    color: #303030;
    text-decoration: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin: 0 0 1.25rem 0;
    color: #051922;
}

h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
    margin: 0;
}

h1,
.h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 4rem;
}

h2,
.h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 3.25rem;
}

h3,
.h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.25rem;
}

h4,
.h4 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
}

h5,
.h5 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
}

h6,
.h6 {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
}

p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.1px;
    line-height: 1.8;
    color: #051922;
    margin: 0 0 1.25rem 0;
}

p:last-child {
    margin: 0;
}

.handwriting {
    font-family: "Caveat", arial, serif;
}

.margin-left-3 {
    margin-left: 3em;
}

.w-40 {
    width: 40%;
}

.w-10 {
    width: 10% !important;
}

/*#region Media adds */
@media only screen and (max-width: 767.96px) {
    h1,
    .h1 {
        font-size: 3rem;
        line-height: 3.25rem;
    }
    h2,
    .h2 {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }
}

@media only screen and (max-width: 575.96px) {
    h1,
    .h1 {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }

    h2,
    .h2 {
        font-size: 2rem;
        line-height: 2.25rem;
    }

    h3,
    .h3 {
        font-size: 1.75rem;
        line-height: 2rem;
    }

    h4,
    .h4 {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}

.maxheight-2{
    font-size: 3.5em;
    
}
/*#endregion */

.pr-3{
    margin-right: 1em !important;
}

