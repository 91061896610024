.filterCategory {
    color: #fff;
    padding: 0.05em 0.5em 0.05em 0.5em;
    border-top-left-radius: 0.55rem !important;
    border-bottom-left-radius: 0.55rem !important;
    background-color: #5f5f5f !important;
}

.filterValue {
    padding-left: 1em;
}

.filter-table{
    width: 100%;
}