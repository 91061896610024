.nav-title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.75rem;
    margin-left: 1.5rem;
    color: white;
    text-shadow:
        0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.nav-link-custom {
    font-weight: 700;
    font-size: 2em;
    text-shadow:
        0px 4px 3px rgba(0, 0, 0, 0.4),
        0px 8px 13px rgba(0, 0, 0, 0.1),
        0px 18px 23px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
    width: 15vh;
    height: 15vh;
    max-width: 120px;
    max-height: 120px;
    margin-right: 10px;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-toggler {
    color: white;
    border-color: white;
}

.navbar-z-index {
    z-index: 1000;
}
